.owl-carousel { margin-bottom: 3rem; }

.slider-placeholder {
	background: url('../img/gallery-placeholder.jpg') no-repeat;
	background-size: 100%;
	margin-bottom: 2rem;
	width: 100%;
	height: 66rem;
}

#slider {
	width: 100%;
	min-height: 66rem;
}

#slider .slide { background-color: white; } 

#slider .slide img {
	height: 66rem;
	margin: 0 auto;
	width: auto;
}

#slider .slide .img__caption {
	margin-left: 10.5rem;
	width: 70%;
}

.owl-nav {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
}

.owl-nav .owl-prev, .owl-nav .owl-next,
.owl-nav .owl-prev img, .owl-nav .owl-next img { display: inline-block; }

.owl-nav .owl-prev,
.owl-nav .owl-next {
	position: absolute;
	transform: translateY(-40%);
}

.owl-nav .owl-prev { left: -3.5rem; }

.owl-nav .owl-next { right: -3.5rem; }

#carousel {
	height: 12.5rem;
	margin-bottom: 6rem;
}

#carousel .owl-nav .owl-prev {
	left: .5rem;
	top: .2rem;
}

#carousel .owl-nav .owl-next {
	right: .5rem;
	top: .2rem;
}

/* Responsive Overrides */
/* Desktop/Tablet Landscape (Large) screen sizes */
@media only screen and (max-width: 1279px) {
	.slider-placeholder { height: 51rem; }
	#slider { min-height: 51rem; }
	#slider .slide img { height: 51rem; }
	#carousel { height: 11rem; }
}

/* Tablet Portrait (Medium) screen sizes */
@media only screen and (max-width: 1023px) {
	.slider-placeholder { height: 45.9rem; }
	#slider { min-height: 45.9rem; }
	#slider .slide img { height: 45.9rem; }
	.owl-nav .owl-prev { left: -1.5rem; }
	.owl-nav .owl-next { right: -1.5rem; }
	#carousel { height: 11.5rem; }
	#carousel .owl-nav .owl-prev { left: .5rem; }
	#carousel .owl-nav .owl-next { right: .5rem; }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@media only screen and (max-width: 767px) {
	.slider-placeholder { height: 25rem; }
	#slider { min-height: 25rem; }
	#slider .slide img { height: 25rem; }
	#slider .slide .img__caption {
    	margin-left: 3rem;
    	width: 92%;
    }
    .owl-nav .owl-prev img,
	.owl-nav .owl-next img { height: 3rem; }
	#carousel { height: 11.5rem; }
	#carousel .owl-nav .owl-prev img,
	#carousel .owl-nav .owl-next img { height: 1.8rem; }
}

/* Mobile Portrait (Extra Small) screen sizes */
@media only screen and (max-width: 459px) {
	.slider-placeholder { height: 19rem; }
	#slider { min-height: 19rem; }
	#slider .slide img { height: 19rem; }
	#slider .slide .img__caption { width: 95%; }
	.owl-nav .owl-prev { left: -.5rem; }
	.owl-nav .owl-next { right: -.5rem; }
	#carousel { display: none; }
}